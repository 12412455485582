<template>
  <ul class="langs">
    <li
      class="langs__item"
      :class="{ active: $i18n.locale === 'he' }"
      @click="$i18n.locale = 'he'"
    >
      <NuxtLink class="langs__item-link">
        <nuxt-icon name="il_flag" />
      </NuxtLink>
    </li>
    <li
      class="langs__item"
      :class="{ active: $i18n.locale === 'en' }"
      @click="$i18n.locale = 'en'"
    >
      <NuxtLink to="" class="langs__item-link">
        <nuxt-icon name="usa_flag" />
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup>
import NuxtIcon from '@/libs/components/common/NuxtIcon.vue'

const i18n = useI18n()
// console.log('ttt', useI18n())

watch(
  () => i18n.locale,
  (locale) => {
    // console.log('locale changed', locale.value)
  },
)
</script>

<style scoped></style>
